import { Text, View, Image } from 'react-native'

export default () => (
    <View style={{ flex: 1, alignItems: 'center', minHeight: 300 }}>
        <View style={{ paddingBottom: 20 }}>
            <Text style={{ color: '#00AB57', fontFamily: 'Anton', fontSize: 36 }}>
                Cook Smarter
            </Text>
        </View>

        <Image source={require('../assets/stores/apple-store-badge.png')} style={{ height: 100, width: 260, resizeMode: 'contain' }} />
        <Image source={require('../assets/stores/google-play-badge.png')} style={{ height: 140, width: 300, resizeMode: 'contain' }} />
    </View>
)