import * as React from "react"
import Svg, { Path, } from "react-native-svg"
const SvgComponent = ({ color, size, ...props }: { color?: string, size?: number }) => (
  <Svg
    style={{
      height: size || 40,
      width: size || 40
    }}
    viewBox={`0 0 35 44`} // TODO: phd in SVG
    {...props}
  >
    <Path
      fill={color || "#d81e05"}
      d="M38.696 14.057a8.327 8.327 0 01-.659 3.256c-1.273 3.006-4.253 5.12-7.716 5.119a8.328 8.328 0 01-4.698-1.443l-.43-.292-.437.283c-1.453.945-3.097 1.444-4.754 1.445-1.878 0-3.705-.625-5.28-1.808l-.482-.361-.473.373a8.277 8.277 0 01-4.222 1.744c-.139.014-.277.029-.416.037-.18.014-.36.023-.541.023-4.618 0-8.374-3.758-8.374-8.375C.213 9.441 3.97 5.683 8.588 5.683a8.395 8.395 0 012.232.31l.609.169.294-.559c1.772-3.374 4.867-5.39 8.278-5.39 3.329.001 6.385 1.941 8.175 5.19l.265.482.545-.086a8.37 8.37 0 011.336-.118c4.617.001 8.373 3.757 8.373 8.376zm-13.522 8.505a10.231 10.231 0 01-5.173 1.428c-2.03 0-4.001-.613-5.738-1.778a9.796 9.796 0 01-4.718 1.735V34.28h20.776l.001-10.28a9.888 9.888 0 01-5.148-1.437z"
    />
  </Svg>
)
export default SvgComponent