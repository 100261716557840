/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MealPlan } from '../models/MealPlan';
import type { MealPlanOptimizationParams } from '../models/MealPlanOptimizationParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MealPlansService {

    /**
     * Create Meal Plan
     * Create a meal plan
     * @returns MealPlan Successful Response
     * @throws ApiError
     */
    public static createMealPlanMealPlansPost({
        requestBody,
    }: {
        requestBody: MealPlanOptimizationParams,
    }): CancelablePromise<MealPlan> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meal_plans/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
