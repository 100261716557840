import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import LoginScreen from "../screens/dashboard/LoginScreen.web"
import HomeScreen from "../screens/dashboard/HomeScreen.web"
import React, { useEffect } from "react"
import { View, Text, ActivityIndicator } from "react-native";
import { DashboardRootStackParamList } from "../types"
import Footer from '../components/Footer';
import { useMediaQuery } from 'react-responsive';
import { RootStackParamList } from '../App.web';
import { LoginService } from '../apis/foodsmarts';


type Props = NativeStackScreenProps<RootStackParamList, 'Dashboard'>;
export default function DashboardNavigator({ route }: Props) {
    const [loading, setLoading] = React.useState(true)
    const [loggedIn, setLoggedIn] = React.useState(false)

    useEffect(() => {
        if (route.params?.magic) {
            LoginService.validateMagicLinkLoginClaimPost({ requestBody: { claim: route.params.magic } })
                .then(() => {
                    setLoading(false)
                    setLoggedIn(true)
                })
        } else {
            LoginService.refreshTokenLoginRefreshPost()
                .then(() => {
                    setLoading(false)
                    setLoggedIn(true)
                })
                .catch(() => {
                    setLoading(false)
                    setLoggedIn(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const Stack = createNativeStackNavigator<DashboardRootStackParamList>()

    const isTiny = useMediaQuery({
        maxWidth: 400
    });

    if (loading) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 30 }}>Loading</Text>
                <ActivityIndicator size="large" />
            </View>
        )
    } else {
        return (
            <Stack.Navigator
                screenLayout={({ children, navigation }) => (
                    <View style={{ flexGrow: 1, flex: 1, flexDirection: 'column' }}>
                        <View style={{ flexGrow: 1 }}>
                            {children}
                        </View>
                        <Footer navigation={navigation} isTiny={isTiny} />
                    </View>
                )}
            >
                {!loggedIn ? (
                    <Stack.Screen
                        name="Login"
                        options={{
                            title: 'Dashboard | Login',
                            header: () => null,
                        }}
                        component={LoginScreen}
                    />
                ) : (
                    <Stack.Screen
                        name="Home"
                        options={{
                            title: 'Dashboard | Home',
                            header: () => null,
                        }}
                        component={HomeScreen}
                    />
                )}
            </Stack.Navigator>
        )
    }
}
