/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DraftRecipe } from '../models/DraftRecipe';
import type { Recipe } from '../models/Recipe';
import type { RecipeURLPostRequest } from '../models/RecipeURLPostRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecipesService {

    /**
     * Read Recipes
     * Return all recipes
     * @returns Recipe Successful Response
     * @throws ApiError
     */
    public static readRecipesRecipesGet({
        _public = true,
        skip,
    }: {
        _public?: boolean,
        skip?: number,
    }): CancelablePromise<Array<Recipe>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recipes/',
            query: {
                'public': _public,
                'skip': skip,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Recipe
     * Create a new recipe
     * @returns Recipe Successful Response
     * @throws ApiError
     */
    public static createRecipeRecipesPost({
        requestBody,
    }: {
        requestBody: DraftRecipe,
    }): CancelablePromise<Recipe> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/recipes/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Recipe
     * @returns Recipe Successful Response
     * @throws ApiError
     */
    public static getRecipeRecipesRecipeIdGet({
        recipeId,
    }: {
        recipeId: string,
    }): CancelablePromise<Recipe> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recipes/{recipe_id}',
            path: {
                'recipe_id': recipeId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Recipe
     * @returns any Successful Response
     * @throws ApiError
     */
    public static putRecipeRecipesRecipeIdPut({
        recipeId,
        requestBody,
    }: {
        recipeId: any,
        requestBody: DraftRecipe,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/recipes/{recipe_id}',
            path: {
                'recipe_id': recipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Recipe
     * Delete recipe
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteRecipeRecipesRecipeIdDelete({
        recipeId,
    }: {
        recipeId: any,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/recipes/{recipe_id}',
            path: {
                'recipe_id': recipeId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Recipe
     * Import a recipe from a URL
     * @returns DraftRecipe Successful Response
     * @throws ApiError
     */
    public static importRecipeRecipesImportPost({
        requestBody,
    }: {
        requestBody: RecipeURLPostRequest,
    }): CancelablePromise<DraftRecipe> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/recipes/import',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
