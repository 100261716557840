/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Cart } from '../models/Cart';
import type { MealPlan } from '../models/MealPlan';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CartsService {

    /**
     * Create Voila Cart
     * Create new cart
     * @returns Cart Successful Response
     * @throws ApiError
     */
    public static createVoilaCartCartsVoilaPost({
        requestBody,
    }: {
        requestBody: MealPlan,
    }): CancelablePromise<Cart> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/carts/voila',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
