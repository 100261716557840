/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Order } from '../models/Order';
import type { OrderRequest } from '../models/OrderRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * Save Voila Order
     * Place order from cart
     * @returns Order Successful Response
     * @throws ApiError
     */
    public static saveVoilaOrderOrdersVoilaPost({
        requestBody,
    }: {
        requestBody: OrderRequest,
    }): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/voila',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
