import React from 'react';
import { ActivityIndicator, TouchableOpacity, Text, StyleSheet, StyleProp, ViewStyle, View } from 'react-native';

type HeroButtonProps = {
    title: string;
    onPress?: () => void;
    loading?: boolean;
    inverted?: boolean;
    color?: string;
    disabled?: boolean;
    style?: StyleProp<ViewStyle>;
}

const HeroButton: React.FC<HeroButtonProps> = ({ title, onPress, color: inputColor, inverted, loading, style, disabled }) => {
    let backgroundColor = inverted ? '#FFFFFF' : inputColor || '#007AFF' // TODO: Hardcoded color to theme
    const color = inverted ? inputColor || '#007AFF' : '#FFFFFF'
    const borderWidth = inverted ? 2 : 0
    const borderColor = color

    if (disabled) {
        backgroundColor = '#CCCCCC'
    }

    return (
        <TouchableOpacity style={[styles.button, { backgroundColor, borderWidth, borderColor }, style]} onPress={onPress} disabled={disabled || loading}>
            {loading &&
                <ActivityIndicator color={color} size={20}/>
            }
            {!loading &&
                <Text style={[styles.title, { color }]}>{title}</Text>
            }
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        height: 60,
        borderRadius: 8,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
});

export default HeroButton;
