import { View, Text, TouchableHighlight, Linking } from 'react-native';

const Footer = ({ isTiny, navigation }) => (
    <View style={{ backgroundColor: '#d81e05', padding: 10, }}>
        <View style={{ flexDirection: isTiny ? 'column' : 'row', justifyContent: 'space-between', maxWidth: 400 }}>
            <Text style={{ color: 'white' }}>© 2024 FoodSmarts</Text>
            <Text style={{ color: isTiny ? 'transparent' : 'white' }}>|</Text>
            <TouchableHighlight underlayColor={'undefined'} onPress={() => Linking.openURL('mailto:contact@foodsmarts.ca')}>
                <Text style={{ color: 'white' }}>Contact Us</Text>
            </TouchableHighlight>
            <Text style={{ color: isTiny ? 'transparent' : 'white' }}>|</Text>
            <TouchableHighlight underlayColor={'undefined'} onPress={() => {
                navigation.navigate('Privacy')
            }}>
                <Text style={{ color: 'white' }}>Privacy Policy</Text>
            </TouchableHighlight>
        </View>
    </View>
)

export default Footer;
