/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebToken } from '../models/WebToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LoginService {

    /**
     * Login With Magic Link
     * First step of a 'magic link' login. Check if the user exists and generate a magic link. Generates two short-duration
     * jwt tokens, one for validation, one for email. Creates user if not exist.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static loginWithMagicLinkLoginMagicEmailPost({
        email,
    }: {
        email: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login/magic/{email}',
            path: {
                'email': email,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Validate Magic Link
     * @returns any Successful Response
     * @throws ApiError
     */
    public static validateMagicLinkLoginClaimPost({
        requestBody,
    }: {
        requestBody: WebToken,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login/claim',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Token
     * Refresh tokens for future requests
     * @returns any Successful Response
     * @throws ApiError
     */
    public static refreshTokenLoginRefreshPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login/refresh',
            errors: {
                404: `Not found`,
            },
        });
    }

}
