import { View, Text, TextInput, Animated } from 'react-native'
import { useMediaQuery } from "react-responsive";
import LogoMini from '../../svgs/LogoMini';
import { useRef, useState } from 'react';
import HeroButton from '../../components/HeroButton';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { ApiError, LoginService } from '../../apis/foodsmarts';

const MAX_DEVICE_WIDTH = 840
const MIN_DEVICE_HEIGHT = 384

export default () => {
    const isLarge = useMediaQuery({
        minWidth: MAX_DEVICE_WIDTH
    });

    const [focused, setFocused] = useState(false);
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const translateY = useRef(new Animated.Value(0)).current;

    const sendLoginEmail = (email: string) => {
        setLoading(true)
        setErrorMessage(null)
        LoginService.loginWithMagicLinkLoginMagicEmailPost({ email })
            .then(_ => {
                setEmailSent(true)
            })
            .catch((err: ApiError) => {
                setErrorMessage("Check if email valid and try again")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <View style={{ flexGrow: 1, flex: 1, alignItems: 'center', justifyContent: isLarge ? 'center' : 'flex-start' }}>
            <View style={{ borderRadius: 28, width: isLarge ? MAX_DEVICE_WIDTH : '100%', minHeight: MIN_DEVICE_HEIGHT, borderWidth: isLarge ? 1 : 0, padding: 36, borderColor: 'lightgrey' }}>
                <LogoMini size={68} />
                {!emailSent &&
                    <View style={{ flex: 1, flexGrow: 1, flexDirection: isLarge ? 'row' : 'column' }}>
                        <View style={{ flex: 1, marginTop: 24, flexGrow: 1, flexBasis: 'auto' }}>
                            <Text style={{ fontSize: 37 }}>Sign in</Text>
                            <Text style={{ fontSize: 20 }}>to continue to Food Smarts</Text>
                        </View>
                        <View style={{ flex: 1, flexGrow: 1, flexBasis: 'auto', marginTop: 10 }}>
                            <View style={{ height: 56, borderWidth: focused ? 2 : 1, borderRadius: 5, borderColor: focused ? '#d81e05' : 'darkgrey' }}>
                                <Animated.Text
                                    style={[
                                        {
                                            bottom: 19,
                                            paddingHorizontal: 10,
                                            left: 3,
                                            color: focused ? '#d81e05' : 'darkgrey',
                                            position: 'absolute',
                                            height: 16,
                                            backgroundColor: 'white',
                                            fontSize: 16,
                                            zIndex: -99,
                                            transform: [{ translateY }]
                                        }
                                    ]}>
                                    Email
                                </Animated.Text>
                                <TextInput
                                    onChangeText={setEmail}
                                    value={email}
                                    onSubmitEditing={() => {
                                        sendLoginEmail(email)
                                    }}
                                    onFocus={() => {
                                        setFocused(true)
                                        Animated.spring(translateY, {
                                            toValue: -28,
                                            useNativeDriver: true,
                                        }).start();
                                    }}
                                    onBlur={() => {
                                        if (!email) {
                                            setFocused(false)
                                            Animated.spring(translateY, {
                                                toValue: 0,
                                                useNativeDriver: true,
                                            }).start();
                                        }
                                    }}
                                    style={{
                                        // @ts-ignore: Unreachable code error
                                        outlineStyle: 'none',
                                        paddingHorizontal: 15,
                                        paddingVertical: 13,
                                        height: 60,
                                        fontSize: 16
                                    }}
                                />
                            </View>
                            <View style={{
                                padding: 3,
                                flexDirection: 'row',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                {errorMessage &&
                                    <>
                                        <MaterialCommunityIcons name='alert-circle' color="red" size={20} />
                                        <Text style={{ fontWeight: 'bold', color: 'red', fontFamily: 'arial' }}>{errorMessage}</Text>
                                    </>
                                }
                            </View>
                            <View style={{ marginVertical: 10, alignItems: 'flex-end' }}>
                                <HeroButton
                                    title='Next'
                                    style={{
                                        width: 100,
                                        height: 48,
                                        borderRadius: 20
                                    }}
                                    loading={loading}
                                    onPress={() => {
                                        sendLoginEmail(email)
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                }
                {emailSent &&
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 36 }}>Link sent to email if valid</Text>
                        <Text style={{ fontSize: 20 }}>Please check your inbox to continue, you may close this window</Text>
                    </View>
                }
            </View>
        </View>
    )
}